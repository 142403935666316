export const daoObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: ' ',
  headline: 'The first DAO,based Metaverse',
  description:
    "Allocate the world's largest DAO-directed treasury, partner with leading protocols, and build the future of finance.",    
  // buttonLabel: "string",
  imgStart: '',
  img: require('../../images/dao.jpg'),
  alt: 'Credit Card',
  start: ''
};

export const daoObjTwo ={
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: ' ',
  headline: 'The first DAO,based Metaverse',
  description:
    "Allocate the world's largest DAO-directed treasury, partner with leading protocols, and build the future of finance.",    
  // buttonLabel: "string",
  imgStart: '',
  img: require('../../images/dao.jpg'),
  alt: 'Credit Card',
  start: ''


}

export const daoObjThree ={
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: ' ',
  headline: 'The first DAO,based Metaverse',
  description:
    "Allocate the world's largest DAO-directed treasury, partner with leading protocols, and build the future of finance.",    
  // buttonLabel: "string",
  imgStart: '',
  img: require('../../images/dao.jpg'),
  alt: 'Credit Card',
  start: ''


}