export const metaverseObjOne={
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headline: 'Unlimited Transactions with zero fees',
    description:
      'Get access to our exclusive diamond card that allows you to  send unlimited transactions without getting charged any fees',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card'
  
};

export const metaverseObjTwo={
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '100% Secure',
    headline: 'Stay protected 24/7 anywhere anytime',
    description:
      'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  
};

export const metaverseObjThree={
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headline: 'Unlimited Transactions with zero fees',
    description:
      'Get access to our exclusive diamond card that allows you to  send unlimited transactions without getting charged any fees',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card'
  
};

export const metaverseObjFour={
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '100% Secure',
    headline: 'Stay protected 24/7 anywhere anytime',
    description:
      'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  
};